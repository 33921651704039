import React from "react";
import { useState, useEffect, useRef } from "react";
import bgImage from '../../assets/img/damir-bosnjak.jpg';

// reactstrap components
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import ButtonAppBar from "components/Navbars/navBar";
// import cameraIcon from "https://res.cloudinary.com/dwckvxlca/image/upload/v1726420509/jvsjh2rfvpnzm6vfluid.png"; // Update with your actual path
import { COLORS } from "colors/color";

const apiUrl = process.env.REACT_APP_API_URL;

function Profile() {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  // const [name, setName] = useState("");
  // const [userName, setUserName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [familyStatus, setFamilyStatus] = useState("");
  const [academicDegree, setAcademicDegree] = useState("");
  const [lastPostOccupied, setLastPostOccupied] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const toast = useRef(null);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  const preset_key = process.env.REACT_APP_PRESET_KEY;
  const cloud_name = process.env.REACT_APP_CLOUD_NAME;

  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  function handleImageClick() {
    fileInputRef.current.click();
  }
useEffect(()=>{
console.log('cloud id is:',cloud_name)
console.log(process.env);

})

  async function loadCandidate() {
    try {
      const response = await fetch(`${apiUrl}candidates/${userId}/find/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"), // Add a space after 'Bearer'
        },
      });
      console.log("response : ", response.data);
      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        // Stop execution of the function after redirecting
        return; // or throw new Error('Unauthorized'); depending on your requirement
      }
      const json = await response.json();
      console.log('json :',json)
      setAcademicDegree(json.academic_degree)
      setDateOfBirth(json.date_of_birth)
      setLastPostOccupied(json.last_post_occupied)
      setFamilyStatus(json.family_status)
      setLinkedInProfile(json.linkedin_profile)
      setImage(json.image)
      // setCandidate(json);
      console.log(json);
      setCompany(json.company);
      setEmail(json.email);
      setFirstName(json.first_name);
      setLastName(json.last_name);
      setAddress(json.address);
      setCity(json.city);
      setCountry(json.country);
      setPostalCode(json.postal_code);
      // setName(`${json.first_name} ${json.last_name}`);
      // setUserName(`${firstName}${lastName}`);
    } catch (error) {
      console.error("Error loading candidate:", error);
    }
  }

  useEffect(() => {
    loadCandidate();
  }, []);

  async function updateCandidate(updatedData) {
    console.log("test 2", updatedData);
    try {
      const response = await axios.patch(
        `${apiUrl}candidates/${userId}/update/`,
        updatedData
      );
      if (response.status === 200) {
        // Handle a successful update (e.g., show a success message)
        console.log(`Post with ID has been updated.`);
        // Reload the post data if needed
        loadCandidate();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Recruter updated",
          life: 3000,
        });
      } else {
        // Handle errors (e.g., show an error message)
        console.error(`Error updating post with ID : ${response.statusText}`);
      }
    } catch (error) {
      // Handle any network errors or exceptions
      console.error(`Error updating post: ${error.message}`);
    }
  }
  function handleFile(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_key);
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      )
      .then((res) => {
        const imageUrl = res.data.secure_url;
        console.log("voila le lien avant de image", imageUrl);
        setImage(imageUrl);
      })
      .catch((err) => console.log(err));
  }
  function handleUpdateCandidate() {
    console.log("test11");
    const updateData = {
      company: company,
      email: email,
      first_name: firstName,
      last_name: lastName,
      address: address,
      city: city,
      country: country,
      postal_code: postalCode,
      image: image,
    };

    updateCandidate(updateData);
  }

  const userId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };

  return (
    <>
      <ButtonAppBar
        isAuth={true}
        onLoginClick={handleLoginClick}
        onSignInClick={handleSignInClick}
      />

      <Toast ref={toast} />

      <div className="content">
        <Row>
          {/* <Col md="4">
            <Card className="card-user">
              
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/yassir.jpg")}
                    />
                    <h5 className="title">{name}</h5>
                  </a>
                  <p className="description">@{userName}</p>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <div className="image" style={{ width: "33%", marginTop:'5%' }}>
            <img alt="..." src={require("assets/img/Profile data-amico.png")} />
          </div> */}
          <div className="col-4">
            <Card className="card-user">
              <div className="image">
                <img alt="imagebg" src={bgImage} />
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div
                      className="image-container"
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                      onClick={handleImageClick}
                      style={{ cursor: "pointer", position: "relative" }}
                    >
                      {!hovered ? (
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={image || require("assets/img/yassir.jpg")}
                        />
                      ) : (
                        <img
                          alt="https://res.cloudinary.com/dwckvxlca/image/upload/v1726332259/zr3up5axpcfqgdrtpckq.png"
                          className="avatar border-gray"
                          src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726420744/zqnb0sinxd54jifyldvg.png"
                          style={{
                            backgroundColor: "gray",
                          }}
                        />
                      )}
                      {hovered && (
                        <img
                          src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726420509/jvsjh2rfvpnzm6vfluid.png"
                          alt=""
                          className="camera-icon"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70px",
                            height: "70px",
                          }}
                        />
                      )}
                    </div>
                    <span
                      className="avatar-name"
                      style={{
                        color: COLORS.signIn,
                        fontSize: "2rem",
                        fontWeight: "bold",
                        marginTop: "0.5rem",
                        display: "block",
                        textAlign: "center",
                        textDecoration: "none",
                      }}
                    >
                      {firstName} {lastName}
                    </span>
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFile}
                  />
                </div>
              </CardBody>
            </Card>
          
        </div>
          <Col md="8">
            <Card className="card-user" style={{  marginTop:'5%' }}>
              <CardHeader>
                <CardTitle tag="h5">Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Company</label>
                        <Input
                          // defaultValue={company}
                          // disabled
                          value={company}
                          onChange={(event) => {
                            setCompany(event.target.value);
                          }}
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          placeholder="Email"
                          type="email"
                          Value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          Value={firstName}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                          placeholder="First Name "
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          Value={lastName}
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Date Of Birth</label>
                        <Input
                          Value={dateOfBirth}
                          onChange={(event) => {
                            setDateOfBirth(event.target.value);
                          }}
                          placeholder="Date Of Birth "
                          type="date"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Family Status</label>
                        <Input
                          Value={familyStatus}
                          onChange={(event) => {
                            setFamilyStatus(event.target.value);
                          }}
                          placeholder="Family status"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Academic Degree</label>
                        <Input
                          Value={academicDegree}
                          onChange={(event) => {
                            setAcademicDegree(event.target.value);
                          }}
                          placeholder="academic degree"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Last Post Occupied</label>
                        <Input
                          Value={lastPostOccupied}
                          onChange={(event) => {
                            setLastPostOccupied(event.target.value);
                          }}
                          placeholder="post occupied"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>LinkedIn Profile</label>
                        <Input
                          Value={linkedInProfile}
                          onChange={(event) => {
                            setLinkedInProfile(event.target.value);
                          }}
                          placeholder="linkedin profile"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          Value={address}
                          onChange={(event) => {
                            setAddress(event.target.value);
                          }}
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          Value={city}
                          onChange={(event) => {
                            setCity(event.target.value);
                          }}
                          placeholder="City"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          Value={country}
                          onChange={(event) => {
                            setCountry(event.target.value);
                          }}
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input
                          Value={postalCode}
                          onChange={(event) => {
                            setPostalCode(event.target.value);
                          }}
                          placeholder="ZIP Code"
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={handleUpdateCandidate}
                        className="btn-round"
                        color="primary"
                        // type="submit"
                      >
                        Update Profile
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
