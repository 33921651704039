import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import PerfectScrollbar from "perfect-scrollbar";
import { COLORS } from "colors/color";
import Chat from "../../views/Chat/Chat";
import createRecruiter from "../../views/admin/createRecruiter";
import ListRecruiter from "../../views/admin/ListRecruiter";
import Settings from "views/Settings";
const SidebarContainer = styled.div`
  width: 280px;
  background: ${COLORS.signIn};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-right: 1px solid rgba(255, 255, 255, 0.18);
  color: ${COLORS.signIn};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  padding: 30px 20px;
  text-align: center;
  img {
    max-width: 140px;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
    &:hover {
      transform: scale(1.05) rotate(2deg);
    }
  }
`;

const NavContainer = styled.nav`
  padding: 20px 0;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
  }
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 15px 25px;
  color: ${COLORS.white};
  text-decoration: none;
  transition: all 0.3s ease;
  margin: 8px 15px;
  border-radius: 16px 0 0 16px;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, ${COLORS.white}, ${COLORS.signIn});
    opacity: 0;
    transition: opacity 0.3s ease, width 0.3s ease;
    z-index: -1;
  }

  &:hover {
    color: ${COLORS.white};
    transform: translateX(10px);
    box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
    &:before {
      opacity: 0.8;
      width: calc(100% + 10px);
    }
  }

  &.active {
    background: ${COLORS.bgColor};
    box-shadow: -4px 0 32px 0 ${COLORS.white};
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-left: 4px solid ${COLORS.Button_OnHold};
    color: ${COLORS.signIn};
    padding-left: 21px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    width: 20px;
    height: 100%;
    background: ${COLORS.bgColor};
    border-radius: 0 10px 10px 0;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scaleY(0);
    transform-origin: center right;
  }

  &:hover:after,
  &.active:after {
    opacity: 1;
    transform: scaleY(1);
  }

  i {
    margin-right: 15px;
    font-size: 20px;
    transition: transform 0.3s ease;
  }

  &:hover i {
    transform: scale(1.2);
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }
`;
const routes = [
  {
    path: "/createRecruiter",
    name: "Create Recruiter",
    icon: "fas fa-user-plus",
    component: <createRecruiter />,
    layout: "/AdminA",
  },
  {
    path: "/ListRecruiter",
    name: "List Recruiters",
    icon: "fas fa-users",
    component: <ListRecruiter />,
    layout: "/AdminA",
  },
  {
    path: "/Chat",
    name: "Chat",
    icon: "fas fa-comment",
    component: <Chat />,
    layout: "/AdminA",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: 'fas fa-cog',
    component: <Settings />,
    layout: "/admin",
  },
];

function Sidebar() {
  const location = useLocation();
  const sidebar = useRef();
  let ps;

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  return (
    <SidebarContainer>
      <Logo>
      <img
        src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726419523/sds674s2tg5so9oxnbw3.png"
        alt="."
        // style={{ width: "90px", marginRight: "16px" }}
      />
      </Logo>
      <NavContainer ref={sidebar}>
        {routes.map((route, index) => (
          <NavItem
            key={index}
            to={route.layout + route.path}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <i className={route.icon} />
            <p>{route.name}</p>
          </NavItem>
        ))}
      </NavContainer>
    </SidebarContainer>
  );
}

export default Sidebar;
