import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, SvgIcon } from "@mui/material";
import { Box, Chip, IconButton } from "@mui/material";
import DevOpsLoop from "assets/img/DevOpsLoop.png"; // Adjust the path according to your project structure
import "./home.css";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { styled } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { COLORS } from "colors/color";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 600,
  height: 310,
  marginBottom: theme.spacing(2),
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
  position: "relative",
  overflow: "hidden",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 8px 16px rgba(0,0,0,0.12)",
  },
}));

const CardContentStyled = styled(CardContent)({
  padding: "2%",
  display: "flex",
  flexDirection: "column",
  height: "50%",
});

const RecruiterAvatar = styled(Avatar)({
  width: 50,
  height: 50,
  border: "2px solid #fff",
  boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
  marginBottom: "8px",
});

const SaveButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 4,
  right: 4,
  padding: 4,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
}));
function MyCard({
  title,
  description,
  deadline,
  image,
  post_id,
  savedBlack,
  refresh,
  recruiter,
}) {
  const [isSaved, setIsSaved] = useState(savedBlack);

  const handleSaveIconClick = async () => {
    console.log("Saved icon clicked!");
    const userId = getUserIdFromAccessToken();

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}candidates/save_post/${userId}/${post_id}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        setIsSaved(!isSaved);
        refresh(); //refreshing the saving posts page
        // Toggle the saved state
      } else {
        console.error(data.error);
        // Handle the error accordingly
      }
    } catch (error) {
      console.error("Error saving the post:", error);
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  return (
    <StyledCard>
      <CardMedia
        component="img"
        height="310"
        image={image || DevOpsLoop}
        alt="Job Post Image"
      />
      <SaveButton onClick={handleSaveIconClick} size="small">
        {isSaved ? (
          <BookmarkIcon color="primary" fontSize="small" />
        ) : (
          <BookmarkBorderOutlinedIcon fontSize="small" />
        )}
      </SaveButton>
      <CardContentStyled>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
          <RecruiterAvatar
            src={recruiter.image ? recruiter.image : "com.png"}
            alt="Recruiter"
            sx={{ marginRight: 1 }}
          />
          <Typography
            variant="caption"
            sx={{ color: COLORS.grey, fontSize: "1.1rem" }}
          >
            {`${recruiter.last_name} ${recruiter.first_name}`}
          </Typography>
        </Box>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", marginBottom: 1, fontSize: "1.2rem" }}
        >
          {title.length > 50 ? `${title.slice(0, 50)}...` : title}
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ flexGrow: 1, marginBottom: 1, fontSize: "0.8rem" }}
        >
          {description.length > 90
            ? `${description.slice(0, 90)}...`
            : description}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Chip
            icon={<AccessTimeIcon fontSize="small" />}
            label={`Deadline: ${deadline}`}
            size="small"
            color={COLORS.button_OnHover}
            variant="outlined"
            sx={{ fontSize: "0.7rem", height: 24 }}
          />
        </Box>
      </CardContentStyled>
    </StyledCard>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCard);
