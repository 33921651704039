import React from "react";
import { useState, useEffect, useRef } from "react";
import bgImage from '../../assets/img/damir-bosnjak.jpg';

// reactstrap components
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
// import cameraIcon from "./camera.png"; // 
import { COLORS } from "colors/color";
import { Button } from "@mui/material";
const cameraIcon = "https://res.cloudinary.com/dwckvxlca/image/upload/v1726420509/jvsjh2rfvpnzm6vfluid.png";

const apiUrl = process.env.REACT_APP_API_URL;

function User() {
  // const [recruiter, setRecruiter] = useState([]);
  const [image, setImage] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [name, setName] = useState("");
  // const [userName, setUserName] = useState("");
  const toast = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  // const preset_key = process.env.PRESET_KEY;
  // const cloud_name = process.env.CLOUD_NAME;
  const preset_key = "tpjsjr7k";
  const cloud_name = "dwckvxlca";

  async function loadRecruiter() {
    try {
      const response = await fetch(`${apiUrl}recruiters/${userId}/find/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"), // Add a space after 'Bearer'
        },
      });
      console.log("response : ", response.data);
      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page ...");
        navigate("/login");
        // Stop execution of the function after redirecting
        return; // or throw new Error('Unauthorized'); depending on your requirement
      }
      const json = await response.json();
      // setRecruiter(json);
      setImage(json.image);
      console.log(json);
      setCompany(json.company);
      setEmail(json.email);
      setFirstName(json.first_name);
      setLastName(json.last_name);
      setAddress(json.address);
      setCity(json.city);
      setCountry(json.country);
      setPostalCode(json.postal_code);
      setName(`${json.first_name} ${json.last_name}`);
      // setUserName(`${firstName}${lastName}`);
    } catch (error) {
      console.error("Error loading recruiter:", error);
    }
  }

  useEffect(() => {
    loadRecruiter();
  }, []);

  useEffect(() => {
    // Log the image URL whenever it updates
    if (image) {
      console.log("Updated image URL:", image);
    }
  }, [image]);

  function handleImageClick() {
    fileInputRef.current.click();
  }

  function handleFile(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_key);
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      )
      .then((res) => {
        const imageUrl = res.data.secure_url;
        console.log("voila le lien avant de image", imageUrl);
        setImage(imageUrl);
      })
      .catch((err) => console.log(err));
  }

  async function updateRecruiter(updatedData) {
    console.log("test 2", updatedData);
    try {
      const response = await axios.patch(
        `${apiUrl}recruiters/${userId}/update/`,
        updatedData
      );
      if (response.status === 200) {
        // Handle a successful update (e.g., show a success message)
        console.log(`Post with ID has been updated.`);
        // Reload the post data if needed
        loadRecruiter();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Recruter updated",
          life: 3000,
        });
      } else {
        // Handle errors (e.g., show an error message)
        console.error(`Error updating post with ID : ${response.statusText}`);
      }
    } catch (error) {
      // Handle any network errors or exceptions
      console.error(`Error updating post: ${error.message}`);
    }
  }
  async function handleUpdateRecruiter(event) {
    event.preventDefault();
    const updateData = {
      company: company,
      email: email,
      first_name: firstName,
      last_name: lastName,
      address: address,
      city: city,
      country: country,
      image: image,
      postal_code: postalCode,
    };
    console.log(updateData)

    updateRecruiter(updateData);
  }

  const userId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id;
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  return (
    <>
      <Toast ref={toast} />

      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <div className="image">
                <img alt="bg_profile_recruiter" src={bgImage} />
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div
                      className="image-container"
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                      onClick={handleImageClick}
                      style={{ cursor: "pointer", position: "relative" }}
                    >
                      {!hovered ? (
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={image || require("assets/img/yassir.jpg")}
                        />
                      ) : (
                        <img
                          alt="https://res.cloudinary.com/dwckvxlca/image/upload/v1726332259/zr3up5axpcfqgdrtpckq.png"
                          className="avatar border-gray"
                          src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726420744/zqnb0sinxd54jifyldvg.png"
                          style={{
                            backgroundColor: "gray",
                          }}
                        />
                      )}
                      {hovered && (
                        <img
                          src={cameraIcon}
                          alt=""
                          className="camera-icon"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70px",
                            height: "70px",
                          }}
                        />
                      )}
                    </div>
                    <span
                      className="avatar-name"
                      style={{
                        color: COLORS.signIn,
                        fontSize: "2rem",
                        fontWeight: "bold",
                        marginTop: "0.5rem",
                        display: "block",
                        textAlign: "center",
                        textDecoration: "none",
                      }}
                    >
                      {name}
                    </span>
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFile}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleUpdateRecruiter}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Company</label>
                        <Input
                          // defaultValue={company}
                          // disabled
                          value={company}
                          onChange={(event) => {
                            setCompany(event.target.value);
                          }}
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          placeholder="Email"
                          type="email"
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          value={firstName}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                          placeholder="First Name "
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          value={lastName}
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          value={address}
                          onChange={(event) => {
                            setAddress(event.target.value);
                          }}
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          value={city}
                          onChange={(event) => {
                            setCity(event.target.value);
                          }}
                          placeholder="City"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          value={country}
                          onChange={(event) => {
                            setCountry(event.target.value);
                          }}
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input
                          value={postalCode}
                          onChange={(event) => {
                            setPostalCode(event.target.value);
                          }}
                          placeholder="ZIP Code"
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <div className="">
                      <Button
                        // onClick={handleUpdateRecruiter}
                        variant="contained"
                        type="submit"
                        sx={{
                          height: "100%",
                          color: COLORS.white,
                          border: "1px solid",
                          borderColor: COLORS.grey,
                          bgcolor: COLORS.signIn,
                          ":hover": {
                            bgcolor: COLORS.Button_OnHold,
                            borderColor: COLORS.signIn,
                            color: COLORS.signIn,
                          },
                        }}
                      >
                        Update Profile
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
