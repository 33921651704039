import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

// Define styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
  },
  tableColWide: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 2,
  },
});

// PDF Document component
const MyDocument = ({ candidatesApplications }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Candidates and Applications for Recruiter's Posts</Text>
        {candidatesApplications.map((candidate) => (
          <View key={candidate.id}>
            <Text style={styles.subtitle}>{candidate.first_name} {candidate.last_name}</Text>

            {/* Table for Candidate Details */}
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Email</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.email}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Username</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.username}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Role</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.role}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Gender</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.gender}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>City</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.city || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Country</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.country || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Academic Degree</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.academic_degree || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Last Post Occupied</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.last_post_occupied || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>LinkedIn Profile</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.linkedin_profile || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Date of Birth</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.date_of_birth || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Family Status</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.family_status || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Field of Study</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.field_of_study || 'N/A'}</Text>
                </View>
              </View>
            </View>

            {/* Applications Table */}
            <Text style={styles.subtitle}>Applications:</Text>
            {candidate.applications.length > 0 ? (
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application ID</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Post Title</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Step</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Status</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Date</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Score Matching</Text>
                  </View>
                </View>
                {candidate.applications.map((app) => (
                  <View style={styles.tableRow} key={app.id}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.id}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.post_title}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.step}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.status}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{new Date(app.date).toLocaleDateString()}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.score_matching}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ) : (
              <Text style={styles.text}>No applications available</Text>
            )}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

const RecruiterCandidatesApplicationsPDF = ({ recruiterId }) => {
  const [candidatesApplications, setCandidatesApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log('Fetching recruiter candidates and applications');
    fetchRecruiterCandidatesApplications();
  }, [recruiterId]);

  const fetchRecruiterCandidatesApplications = async () => {
    try {
      console.log('recruiterId', recruiterId);
      setLoading(true);
      const response = await axios.get(`${apiUrl}recruiters/${recruiterId}/candidates-applications/`);
      setCandidatesApplications(response.data);
      setLoading(false);
      console.log('data : ', response.data);
    } catch (err) {
      setError('An error occurred while fetching data');
      setLoading(false);
    }
  };

  const generatePDF = async () => {
    const blob = await pdf(<MyDocument candidatesApplications={candidatesApplications} />).toBlob();
    saveAs(blob, 'recruiter_candidates_applications.pdf');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="recruiter-candidates-applications-pdf">
      <h2>Candidates and Applications for Recruiter's Posts</h2>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default RecruiterCandidatesApplicationsPDF;
