import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

const RecruiterCandidatesApplications = ({ recruiterId }) => {
  const [candidatesApplications, setCandidatesApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log('Fetching recruiter candidates and applications');
    fetchRecruiterCandidatesApplications();
  }, [recruiterId]);

  const fetchRecruiterCandidatesApplications = async () => {
    try {
      console.log('recruiterId', recruiterId);
      setLoading(true);
      const response = await axios.get(`${apiUrl}recruiters/${recruiterId}/candidates-applications/`);
      setCandidatesApplications(response.data);
      setLoading(false);
      console.log('data : ', response.data);
    } catch (err) {
      setError('An error occurred while fetching data');
      setLoading(false);
    }
  };

  const generateCSV = () => {
    const csvData = candidatesApplications.flatMap(candidate => 
      candidate.applications.map(app => ({
        'Candidate ID': candidate.id,
        'First Name': candidate.first_name,
        'Last Name': candidate.last_name,
        'Email': candidate.email,
        'Username': candidate.username,
        'Role': candidate.role,
        'Gender': candidate.gender,
        'City': candidate.city || '',
        'Country': candidate.country || '',
        'Academic Degree': candidate.academic_degree || '',
        'Last Post Occupied': candidate.last_post_occupied || '',
        'LinkedIn Profile': candidate.linkedin_profile || '',
        'Date of Birth': candidate.date_of_birth || '',
        'Family Status': candidate.family_status || '',
        'Field of Study': candidate.field_of_study || '',
        'Application ID': app.id,
        'Application Step': app.step,
        'Application Status': app.status,
        'Resume Path': app.resume_path,
        'Cover Letter': app.cover_letter,
        'Application Date': new Date(app.date).toLocaleDateString(),
        'Score Matching': app.score_matching,
        'Post ID': app.post,
        'Post Title': app.post_title
      }))
    );

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'recruiter_candidates_applications.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="recruiter-candidates-applications">
      <h2>Candidates and Applications for Recruiter's Posts</h2>
      <button onClick={generateCSV}>Generate CSV</button>
      {candidatesApplications.length > 0 ? (
        <ul>
          {candidatesApplications.map(candidate => (
            <li key={candidate.id} className="candidate-item">
              <h3>{candidate.first_name} {candidate.last_name}</h3>
              <div className="candidate-details">
                <p><strong>Email:</strong> {candidate.email}</p>
                <p><strong>Username:</strong> {candidate.username}</p>
                <p><strong>Role:</strong> {candidate.role}</p>
                <p><strong>Gender:</strong> {candidate.gender}</p>
                <p><strong>City:</strong> {candidate.city || 'N/A'}</p>
                <p><strong>Country:</strong> {candidate.country || 'N/A'}</p>
                <p><strong>Academic Degree:</strong> {candidate.academic_degree || 'N/A'}</p>
                <p><strong>Last Post Occupied:</strong> {candidate.last_post_occupied || 'N/A'}</p>
                <p><strong>LinkedIn Profile:</strong> {candidate.linkedin_profile || 'N/A'}</p>
                <p><strong>Date of Birth:</strong> {candidate.date_of_birth || 'N/A'}</p>
                <p><strong>Family Status:</strong> {candidate.family_status || 'N/A'}</p>
                <p><strong>Field of Study:</strong> {candidate.field_of_study || 'N/A'}</p>
              </div>
              <h4>Applications:</h4>
              {candidate.applications.length > 0 ? (
                <ul className="applications-list">
                  {candidate.applications.map(app => (
                    <li key={app.id} className="application-item">
                      <p><strong>Application ID:</strong> {app.id}</p>
                      <p><strong>Post:</strong> {app.post_title} (ID: {app.post})</p>
                      <p><strong>Step:</strong> {app.step}</p>
                      <p><strong>Status:</strong> {app.status}</p>
                      <p><strong>Date:</strong> {new Date(app.date).toLocaleDateString()}</p>
                      <p><strong>Score Matching:</strong> {app.score_matching}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No applications for this candidate.</p>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No candidates or applications found for this recruiter's posts.</p>
      )}
    </div>
  );
};

export default RecruiterCandidatesApplications;