import React, { useEffect, useState } from "react";
import { Link, NavLink as RouterNavLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Height } from "@mui/icons-material";

import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { COLORS } from "colors/color";
import { Popover, List, ListItem, ListItemText } from "@mui/material";
import useSignalingStore from "../../views/signalingStore";
import useComponentZoom from "views/UseComponentZoom";
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
const StyledAppBar = styled(AppBar)({
  backgroundColor: COLORS.signIn,
  boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.1)",
});

const NavLink = styled(RouterNavLink)({
  color: COLORS.white,
  textDecoration: "none",
  padding: "8px 16px",
  marginRight: "16px",
  fontWeight: 600,
  borderRadius: "4px",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: COLORS.white,
    color: COLORS.signIn,
  },
  "&.active": {
    backgroundColor: COLORS.Button_OnHold,
    color: COLORS.signIn,
  },
});

const StyledButton = styled(Button)({
  marginLeft: "30px",
  borderRadius: "4px",
  padding: "6px 16px",
  transition: "all 0.3s ease",
});

const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const authRoutes = [
  {
    path: "/home",
    pro: false,
    icon: "home-icon-class",
    name: "Home",
    layout: "",
  },
  {
    path: "/saved_posts",
    pro: false,
    icon: "blog-icon-class",
    name: "Saved posts",
    layout: "",
  },
  {
    path: "/post_applied",
    pro: false,
    icon: "about-icon-class",
    name: "Posts applied",
    layout: "",
  },
  {
    path: "https://pulse.digital/contact/",
    pro: false,
    icon: "about-icon-class",
    name: "Contact us",
    layout: "",
  },
];

const noAuthRoutes = [
  {
    path: "/home",
    pro: false,
    icon: "home-icon-class",
    name: "Home",
    layout: "",
  },
  {
    path: "/a-propos",
    pro: false,
    icon: "about-icon-class",
    name: "About us",
    layout: "",
  },
  {
    path: "https://pulse.digital/contact/",
    pro: false,
    icon: "about-icon-class",
    name: "Contact us",
    layout: "",
  },
];

// const StyledButton = styled(Button)({
//   marginLeft: '10px',
// });

const ButtonAppBar = ({
  isAuth,
  onLoginClick,
  onSignInClick,
  addPost,
  setChatComponent,
}) => {
  const {
    signalingServer,
    addMessage,
    addNotification,
    notifications,
    notificationMessages,
    incrementNotification,
    clearNotifications,
    fetchUnreadNotifications,
    markNotificationsAsRead,
    markNotificationAsRead,
    unreadNotifications,
    notificationCounts,
    getTotalNotifications,
    messagenotification,
    clearMessageNotifications,
    removeMessageNotification,
    updateNotificationCount,
    setInitialNotificationCounts,
    addMessageNotification,
  } = useSignalingStore();
  const [totalNotifications, setTotalNotifications] = useState(0);
  useEffect(() => {
    fetchUnreadMessageCounts();
    const cleanupSocket = connectToSocket();

    return () => {
      if (cleanupSocket) cleanupSocket();
    };
  }, []);

  useEffect(() => {
    setTotalNotifications(getTotalNotifications());
  }, [notificationCounts, getTotalNotifications]);

  const fetchUnreadMessageCounts = async () => {
    try {
      const response = await fetch(

        `${apiUrl}messaging/api/unread-message-counts/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unread message counts");
      }
      const data = await response.json();
      setInitialNotificationCounts(data);
    } catch (error) {
      console.error("Error fetching unread message counts:", error);
    }
  };

  const connectToSocket = () => {
    const senderId = getUserIdFromAccessToken();
    if (senderId) {

      const signalingServerUrl = `${apiUrlsoket}ws/chat/${senderId}/0/`;
      const socket = new WebSocket(signalingServerUrl);

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "chat_message") {
          updateNotificationCount(data.sender_id);
          addMessageNotification({
            id: Date.now(),
            message: data.message,
          });
        }
      };

      return () => {
        socket.close();
      };
    }
  };
  const [isAuth2, setIsAuth2] = useState(isAuth);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const navigate = useNavigate();
  const menuId = "primary-search-account-menu";

  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());

  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(profileAnchorEl);
  const isNotificationPopoverOpen = Boolean(notificationAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const navToProfile = () => {
    setProfileAnchorEl(null);
    handleMobileMenuClose();
    navigate("/profile");
  };
  const logOut = () => {
    setProfileAnchorEl(null);
    handleMobileMenuClose();
    localStorage.clear();
    if (window.location.pathname === "/home") {
      window.location.reload();
    } else {
      navigate("/home");
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [notifMessage, setNotifMessage] = useState(1);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={() => setProfileAnchorEl(null)}
    >
      <MenuItem onClick={navToProfile}>Profile</MenuItem>
      <MenuItem onClick={logOut}> LogOut</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={totalNotifications} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge
            badgeContent={notifMessage}
            color="error"
            onClick={() => {
              setNotifMessage(0);
            }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    setIsAuth2(isAuth);
    console.log("auth", isAuth2);
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) {
      fetchUnreadNotifications();
    }
  }, [isAuth, fetchUnreadNotifications]);

  useEffect(() => {
    console.log("Notifications:", notifications);
  }, [notifications]);

  useEffect(() => {
    if (signalingServer) {
      signalingServer.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("Received message:", message);

        addMessage(message);

        if (message.type === "notif") {
          addNotification({
            content: message.notif,
            timestamp: new Date().toISOString(),
            link: message.link,
          });
          console.log("Received notification: final", message);
        }
      };
    }
  }, [signalingServer, addMessage, addNotification]);

  const handleNotificationClick = (event, link) => {
    setNotificationAnchorEl(event.currentTarget);

    handleNotificationClose();
    if (link) {
      navigate(link);
    }
  };
  // <<<<<<< HEAD
  // =======
  //   const isNotificationPopoverOpen = Boolean(anchorEl);
  // >>>>>>> 7645a8bcb5486146cfaead0191bdc10a57193c00
  const popoverId = isNotificationPopoverOpen
    ? "notification-popover"
    : undefined;
  const userId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
    markNotificationsAsRead();
  };
  const handleNotificationButtonClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    fetchUnreadNotifications();
  };

  const handleNotificationItemClick = (event, notification) => {
    markNotificationAsRead(notification.id);
    handleNotificationClose(); // Close the popover after clicking
    if (notification.link) {
      navigate(notification.link);
    }
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
      <img
        src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726419523/sds674s2tg5so9oxnbw3.png"
        alt="."
        style={{ width: "90px", marginRight: "16px" }}
      />
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          {(isAuth ? authRoutes : noAuthRoutes).map((route, index) => (
            <NavLink key={index} to={route.path}>
              {route.name}
            </NavLink>
          ))}
        </Box>

        {isAuth ? (
          <IconWrapper>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => setChatComponent(true)}
            >
              <Badge
                badgeContent={totalNotifications}
                color="error"
                // onClick={() => {
                //   setNotifMessage(0);
                // }}
              >
                <MailIcon />
              </Badge>
            </IconButton>
            <div className="notifications">
              <IconButton
                size="large"
                aria-label="show notifications"
                color="inherit"
                onClick={handleNotificationButtonClick}
              >
                <Badge
                  badgeContent={
                    Array.isArray(unreadNotifications)
                      ? unreadNotifications.length
                      : 0
                  }
                  color="error"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Popover
                id={popoverId}
                open={isNotificationPopoverOpen}
                anchorEl={notificationAnchorEl}
                onClose={handleNotificationClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List>
                  {Array.isArray(notifications) && notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <ListItem
                        key={notification.id}
                        button
                        onClick={(event) =>
                          handleNotificationItemClick(event, notification)
                        }
                        style={{
                          backgroundColor: notification.read
                            ? "transparent"
                            : "#e0e0e0",
                        }}
                      >
                        <ListItemText
                          primary={notification.content || notification.text}
                          secondary={
                            notification.timestamp &&
                            new Date(notification.timestamp).toLocaleString()
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary="No notifications" />
                    </ListItem>
                  )}
                </List>
              </Popover>
            </div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {userRole === "r" ? (
              <StyledButton
                variant="contained"
                onClick={() =>
                  navigate("/admin/dashboard", { state: { recruiter: true } })
                }
                sx={{
                  backgroundColor: COLORS.Button_OnHold,
                  color: COLORS.signIn,
                  "&:hover": {
                    backgroundColor: COLORS.pulse_color,
                    color: COLORS.white,
                  },
                }}
              >
                Recruiter Space
              </StyledButton>
            ) : (
              !addPost && (
                <></>
                // <StyledButton
                //   variant="contained"
                //   onClick={() => navigate("/addpost")}
                //   sx={{
                //     backgroundColor: COLORS.Button_OnHold,
                //     color: COLORS.signIn,
                //     "&:hover": {
                //       backgroundColor: COLORS.pulse_color,
                //       color: COLORS.white,
                //     },
                //   }}
                // >
                //   Post Job
                // </StyledButton>
              )
            )}
          </IconWrapper>
        ) : (
          <>
            {/* <StyledButton
              variant="outlined"
              onClick={() => (window.location.href = "../login")}
              sx={{
                color: COLORS.signIn,
                backgroundColor: COLORS.white,
                borderColor: COLORS.white,
                "&:hover": {
                  backgroundColor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                },
              }}
            >
              SIGN IN
            </StyledButton> */}
            <StyledButton
              variant="contained"
              // onClick={onSignInClick}
              onClick={() => (window.location.href = "../login")}
              sx={{
                backgroundColor: COLORS.pulse_color,
                color: COLORS.white,
                "&:hover": {
                  color: COLORS.signIn,
                  backgroundColor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                },
              }}
            >
                            LOGIN

            </StyledButton>
          </>
        )}
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </StyledAppBar>
  );
};

export default ButtonAppBar;
