export const COLORS = {
  primary_button: "#9cd6d1",
  onHold_button: "#009688",
  pulse_color: "#ff0404",
  signIn: "#000",
  grey: "#707070",
  white: "#fff",
  Button_OnHold: "#E1E1E1",
  bgColor: "#f4f2ee",
  primary_color: "#F4F5F7"
};
