import React, { useEffect, useRef, useState } from "react";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import ButtonAppBar from "components/Navbars/navBar";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Container, Row } from "reactstrap";
import "./home.css";
import MyArrayInput from "../MyArrayInput";
import { COLORS } from "../../colors/color";
import axios from "axios";
import { PersonAdd } from "@mui/icons-material";
import dayjs from "dayjs";

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { InputTextarea } from "primereact/inputtextarea";
import ImageUploadButton from "views/ImageUploadButton";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { DatePicker } from "@mui/x-date-pickers";
import "react-phone-input-2/lib/style.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useForm } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";


const PersonalInfo = ({
    goToNextStep,
    academicDegree,
    setAcademicDegree,
    lastPostOccupied,
    setLastPostOccupied,
    linkedinProfile,
    setLinkedinProfile,
    dateOfBirth,
    setDateOfBirth,
    fieldOfStudy,
    setFieldOfStudy,
    familyStatus,
    setFamilyStatus
}) => {

  // Error states
  const [academicDegreeError, setAcademicDegreeError] = useState("");
  const [fieldOfStudyError, setFieldOfStudyError] = useState("");
  const [lastPostOccupiedError, setLastPostOccupiedError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");

  const handleAcademicDegreeChange = (event) => {
    const value = event.target.value;
    setAcademicDegree(value);
    setAcademicDegreeError(value ? "" : "Academic Degree is required");
  };

  const handleFieldOfStudyChange = (event) => {
    const value = event.target.value;
    setFieldOfStudy(value);
    setFieldOfStudyError(value ? "" : "Field of Study is required");
  };

  const handleLastPostOccupiedChange = (event) => {
    const value = event.target.value;
    setLastPostOccupied(value);
    setLastPostOccupiedError(value ? "" : "Last Post Occupied is required");
  };

  const handleLinkedinProfileChange = (event) => {
    setLinkedinProfile(event.target.value);
  };

  const handleDateOfBirthChange = (newValue) => {
    setDateOfBirth(newValue);
    setDateOfBirthError(newValue ? "" : "Date of Birth is required");
  };

  const handleFamilyStatusChange = (event) => {
    setFamilyStatus(event.target.value);
  };

  const handleContinue = () => {
    let isValid = true;

    if (!academicDegree) {
      setAcademicDegreeError("Academic Degree is required");
      isValid = false;
    }

    if (!fieldOfStudy) {
      setFieldOfStudyError("Field of Study is required");
      isValid = false;
    }

    if (!lastPostOccupied) {
      setLastPostOccupiedError("Last Post Occupied is required");
      isValid = false;
    }

    if (!dateOfBirth) {
      setDateOfBirthError("Date of Birth is required");
      isValid = false;
    }

    if (isValid) {
      goToNextStep();
    }
  };

  return (
    <Container
      style={{ border: "1px solid #efd", width: "70%", borderRadius: "10px" }}
    >
      <div className="content">
        <Box sx={{ width: "100%" }}>
          <FormControl
            fullWidth
            sx={{ width: "100%", marginTop: "2%" }}
            required
            error={!!academicDegreeError}
          >
            <InputLabel id="academic-degree-label">Academic Degree</InputLabel>
            <Select
              labelId="academic-degree-label"
              id="academic-degree"
              value={academicDegree}
              onChange={handleAcademicDegreeChange}
              label="Academic Degree"
            >
              <MenuItem value="baccalaureat">Baccalauréat</MenuItem>
              <MenuItem value="deug">
                DEUG (Diplôme d'Etudes Universitaires Générales)
              </MenuItem>
              <MenuItem value="licence">Licence</MenuItem>
              <MenuItem value="master">Master</MenuItem>
              <MenuItem value="doctorat">Doctorat</MenuItem>
              <MenuItem value="ingenieur">Diplôme d'Ingénieur</MenuItem>
              <MenuItem value="dut">
                DUT (Diplôme Universitaire de Technologie)
              </MenuItem>
              <MenuItem value="bts">
                BTS (Brevet de Technicien Supérieur)
              </MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {academicDegreeError && (
              <div style={{ color: "red", fontSize: "0.75rem" }}>
                {academicDegreeError}
              </div>
            )}
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", marginBottom: "16px" }}>
          <FormControl
            fullWidth
            sx={{ width: "100%", marginTop: "2%" }}
            required
            error={!!fieldOfStudyError}
          >
            <InputLabel id="field-of-study-label">Field of Study</InputLabel>
            <Select
              labelId="field-of-study-label"
              id="field-of-study"
              value={fieldOfStudy}
              onChange={handleFieldOfStudyChange}
              label="Field of Study"
            >
              <MenuItem value="computer-science">Computer Science</MenuItem>
              <MenuItem value="business-administration">
                Business Administration
              </MenuItem>
              <MenuItem value="mechanical-engineering">
                Mechanical Engineering
              </MenuItem>
              <MenuItem value="electrical-engineering">
                Electrical Engineering
              </MenuItem>
              <MenuItem value="civil-engineering">Civil Engineering</MenuItem>
              <MenuItem value="medicine">Medicine</MenuItem>
              <MenuItem value="law">Law</MenuItem>
              <MenuItem value="psychology">Psychology</MenuItem>
              <MenuItem value="economics">Economics</MenuItem>
              <MenuItem value="education">Education</MenuItem>
              <MenuItem value="chemistry">Chemistry</MenuItem>
              <MenuItem value="biology">Biology</MenuItem>
              <MenuItem value="physics">Physics</MenuItem>
              <MenuItem value="art-design">Art and Design</MenuItem>
              <MenuItem value="literature">Literature</MenuItem>
              <MenuItem value="history">History</MenuItem>
              <MenuItem value="sociology">Sociology</MenuItem>
              <MenuItem value="political-science">Political Science</MenuItem>
              <MenuItem value="philosophy">Philosophy</MenuItem>
              <MenuItem value="mathematics">Mathematics</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {fieldOfStudyError && (
              <div style={{ color: "red", fontSize: "0.75rem" }}>
                {fieldOfStudyError}
              </div>
            )}
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", marginBottom: "16px" }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Last post occupied"
            id="Last post occupied"
            required
            value={lastPostOccupied}
            onChange={handleLastPostOccupiedChange}
            error={!!lastPostOccupiedError}
            helperText={lastPostOccupiedError}
          />
        </Box>
        <Box sx={{ width: "100%", marginBottom: "16px" }}>
          <TextField
            fullWidth
            variant="outlined"
            label="LinkedIn profile"
            id="LinkedIn profile"
            value={linkedinProfile}
            onChange={handleLinkedinProfileChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            marginBottom: "5%",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date Of Birth"
                  value={dateOfBirth ? dayjs(dateOfBirth) : null}
                  onChange={handleDateOfBirthChange}
                  format="YYYY-MM-DD"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: "outlined",
                      required: true,
                      error: !!dateOfBirthError,
                      helperText: dateOfBirthError,
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Family status"
              id="Family status"
              value={familyStatus}
              onChange={handleFamilyStatusChange}
            />
          </Box>
        </Box>

        <Row>
          <div
            style={{ textAlign: "right", marginTop: "4%", marginBottom: "2%" }}
          >
            <Button
              variant="contained"
              onClick={handleContinue}
              sx={{
                height: "130%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Continue <EastIcon />
            </Button>
          </div>
        </Row>
      </div>
    </Container>
  );
};

const ApplyInfo = ({
    goBackStep,
    addPost,
    selectedFile,
    setSelectedFile,
    uploadedResume,
    setUploadedResume,
    uploadedCoverLetter,
    setUploadedCoverLetter,

}) => {
  
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [coverLetterUploaded, setCoverLetterUploaded] = useState(false);
  const [error, setError] = useState("");

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (uploadedResume) {
      setResumeUploaded(true);
    }
  }, [uploadedResume]);

  const handleResumeChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file.name);
      setUploadedResume(file);
      setResumeUploaded(true);
    }
  };

  const handleCoverLetterChange = (event) => {
    if (event.target.files.length > 0) {
        const file = event.target.files[0];
      setCoverLetterUploaded(true);
      setUploadedCoverLetter(file)
      console.log(file)
    }
  };

  const handleFileSelect = (fileKey, file) => {
    setSelectedFile(fileKey);
    setResumeUploaded(true);
    setUploadedResume(file);
  };

  return (
    <Container
      style={{
        border: "1px solid #efd",
        width: "70%",
        borderRadius: "10px",
        paddingBottom: "2%",
        marginBottom: "4%",
      }}
    >
      <div className="content">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
            Upload Your Documents
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Please upload your resume and cover letter to complete your
            application.
          </Typography>
        </Box>
        <Box sx={{ width: "100%", marginTop: "3%" }}>
          <label
            htmlFor="resume-upload"
            style={{ display: "block", cursor: "pointer" }}
          >
            <Card
              sx={{
                mb: 3,
                borderRadius: "12px",
                boxShadow: "none",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
                },
                bgcolor: resumeUploaded ? "rgba(0,0,0,0.05)" : "#fff",
                border: "2px dashed",
                borderColor: resumeUploaded ? "#000" : "#E1E1E1",
              }}
            >
              <CardContent sx={{ padding: "24px" }}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <CloudUploadIcon sx={{ color: "#000", fontSize: "48px" }} />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      sx={{ color: "#000", fontWeight: 600 }}
                    >
                      {resumeUploaded
                        ? "Resume Uploaded"
                        : "Upload your Resume - CV"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#707070", mt: 1 }}
                    >
                      {resumeUploaded
                        ? selectedFile
                        : "Accepted formats: PDF, DOCX, RTF, TXT"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </label>
          <input
            id="resume-upload"
            type="file"
            accept=".pdf,.docx,.rtf,.txt"
            style={{ display: "none" }}
            {...register("resume", {
              onChange: (e) => {
                handleResumeChange(e);
                handleFileSelect("resume", e.target.files[0]);
              },
            })}
          />

          <label
            htmlFor="cover-letter-upload"
            style={{ display: "block", cursor: "pointer" }}
          >
            <Card
              sx={{
                mb: 3,
                borderRadius: "12px",
                boxShadow: "none",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
                },
                bgcolor: coverLetterUploaded ? "rgba(0,0,0,0.05)" : "#fff",
                border: "2px dashed",
                borderColor: coverLetterUploaded ? "#000" : "#E1E1E1",
              }}
            >
              <CardContent sx={{ padding: "24px" }}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <AttachFileIcon sx={{ color: "#000", fontSize: "48px" }} />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      sx={{ color: "#000", fontWeight: 600 }}
                    >
                      {coverLetterUploaded
                        ? "Cover Letter Uploaded"
                        : "Upload your Cover Letter"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#707070", mt: 1 }}
                    >
                      {coverLetterUploaded
                        ? "Cover Letter file uploaded"
                        : "We'll guide you through the process"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </label>
          <input
            id="cover-letter-upload"
            type="file"
            accept=".pdf,.docx,.rtf,.txt"
            style={{ display: "none" }}
            {...register("coverLetter", {
              onChange: handleCoverLetterChange,
            })}
          />
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Row>
          <div className="col-6" style={{ textAlign: "left", marginTop: "4%" }}>
            <Button
              variant="contained"
              sx={{
                height: "130%",
                color: COLORS.grey,
                border: "1px solid", // Ensure border is visible
                borderColor: "#8B8B8B",
                bgcolor: "transparent",
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  color: COLORS.signIn,
                  borderColor: COLORS.signIn,
                },
              }}
              onClick={goBackStep}
            >
              <KeyboardBackspaceIcon /> Back
            </Button>
          </div>
          <div
            className="col-6"
            style={{ textAlign: "right", marginTop: "4%" }}
          >
            <Button
              variant="contained"
              onClick={addPost}
              sx={{
                height: "130%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Apply 
            </Button>
          </div>
        </Row>
      </div>
    </Container>
  );
};

function AddPost() {
    
  //---------Personal Info Variables -----------------

  const accessToken = localStorage.getItem("accessToken");
  const [academicDegree, setAcademicDegree] = useState("");
  const [lastPostOccupied, setLastPostOccupied] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [familyStatus, setFamilyStatus] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState("");
  
  //---------Post Info Variables-------------------
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedResume, setUploadedResume] = useState();
  const [uploadedCoverLetter, setUploadedCoverLetter] = useState();
  //----------Job Desc Variables -----------
  
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const job = location.state?.job;
  // Helper function to parse JWT
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
//---------------logs API-----------------------------------
// Logging an action
const logAction = async (action, details) => {
    const response = await fetch('/api/candidate-logs/log_action/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ action, details })
    });
    if (!response.ok) throw new Error('Failed to log action');
    return await response.json();
  };
  
  // Getting action history
  const getActionHistory = async () => {
    const response = await fetch('/api/candidate-logs/get_history/', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if (!response.ok) throw new Error('Failed to fetch action history');
    return await response.json();
  };



  // Function to get user ID from access token
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  const userId = getUserIdFromAccessToken();

  useEffect(() => {
    console.log("addpost userId: ", userId);
  }, [userId]);

  
  async function ApplyToPost() {
    // const postData = {
    //     academicDegree:academicDegree,
    //     lastPostOccupied: lastPostOccupied,
    //     linkedinProfile: linkedinProfile,
    //     dateOfBirth: dateOfBirth.format("YYYY-MM-DD"),
    //     familyStatus:familyStatus,
    //     fieldOfStudy:fieldOfStudy,
    //     selectedFile:selectedFile,
    //     uploadedResume:uploadedResume
    //   };
      const applicationFormData = {
        post: job.id,
        candidate: userId,
        status: "p",
        cover_letter: uploadedCoverLetter,
        resume_path: 'uploadedResume',
        resume: uploadedResume,

      }
      const candidateFormData = new FormData();
      
      console.log('test1: ',job);
      // Application data
      
      console.log('test2');
      // Candidate data
      candidateFormData.append("academic_degree", academicDegree);
      candidateFormData.append("last_post_occupied", lastPostOccupied);
      candidateFormData.append("linkedin_profile", linkedinProfile);
      candidateFormData.append("field_of_study", fieldOfStudy);
      candidateFormData.append(
        "date_of_birth",
        dateOfBirth ? dateOfBirth.format("YYYY-MM-DD") : ""
      );
      candidateFormData.append("family_status", familyStatus);
      
      console.log('postD :',applicationFormData)
      const applicationResponse = await axios.post(
        `${apiUrl}candidates/application/create/`,
       { post: job.id,
        candidate: userId,
        status: "p",
        cover_letter: uploadedCoverLetter,
        resume_path: 'uploadedResume',
        resume: uploadedResume},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Application submitted:", applicationResponse.data);
      return applicationResponse;
    };
  

  const items = [
    {
      label: "Personal Info",
      command: (event) =>
        toast.current.show({
          severity: "info",
          summary: "Personal Info",
          detail: event.item.label,
        }),
    },
    {
      label: "Post Info",
      command: (event) =>
        toast.current.show({
          severity: "info",
          summary: "Post Info",
          detail: event.item.label,
        }),
    },
  ];

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="center-content container">
            <PersonalInfo
              goToNextStep={goToNextStep}
              academicDegree={academicDegree}
              setAcademicDegree={setAcademicDegree}
              lastPostOccupied={lastPostOccupied}
              setLastPostOccupied={setLastPostOccupied}
              linkedinProfile={linkedinProfile}
              setLinkedinProfile={setLinkedinProfile}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              fieldOfStudy={fieldOfStudy}
              setFieldOfStudy={setFieldOfStudy}
              familyStatus={familyStatus}
              setFamilyStatus={setFamilyStatus}
            />
          </div>
        );
      case 1:
        return (
          <div className="center-content container">
            <ApplyInfo 
              goBackStep={goBackStep}
              addPost={ApplyToPost}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              uploadedResume={uploadedResume}
              setUploadedResume={setUploadedResume}
              uploadedCoverLetter={uploadedCoverLetter}
              setUploadedCoverLetter={setUploadedCoverLetter}

            />
          </div>
        );

      default:
        return null;
    }
  };

  const goToNextStep = () => {
    if (activeIndex < items.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const goBackStep = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <>
      <ButtonAppBar isAuth={true} addPost={true} />
      <div className="container" style={{ marginTop: "3%" }}>
        <Toast ref={toast}></Toast>
        <Steps
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={false}
        />
        <div
          className="center-content container"
          style={{ width: "70%", marginTop: "5%", borderRadius: "20px" }}
        >
          {activeIndex === 0 && (
            <img alt="..." src={require("assets/img/emp2.png")} />
          )}
          {activeIndex === 1 && (
            <img alt="..." src={require("assets/img/details.png")} />
          )}
          {activeIndex === 2 && (
            <img alt="..." src={require("assets/img/describe.png")} />
          )}
          {activeIndex === 3 && (
            <img alt="..." src={require("assets/img/conf.png")} />
          )}
        </div>
        <div style={{ marginTop: "3%" }}>{renderStepContent(activeIndex)}</div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPost);
