import React, { useState, useRef, useEffect } from "react";
import "./Chat.css";
import {  IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useLocation } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import useSignalingStore from "../signalingStore";
import { set } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const signalingServer = useRef(null);
  const peerConnection = useRef(null);
  const ringAudioRef = useRef(null);
  const historyRef = useRef(null);
  const callTypeRef = useRef("video");
  // const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [callStatus, setCallStatus] = useState("idle");
  const [messages, setMessages] = useState([]);
  const [Messageshystory, setMessageshystory] = useState([]);

  const [receivedMessages, setReceivedMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [UsersConv, setUsersConv] = useState([]);
  const [screenSharing, setScreenSharing] = useState(false);
  const screenStreamRef = useRef(null);

  const currentDate = new Date();
  const options = { hour: "2-digit", minute: "2-digit" };
  // const timeString = currentDate.toLocaleTimeString(undefined, options);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const screenTrackRef = useRef(null);
  const [updateconv, setupdateconv] = useState(null);
  const [incall, setIncall] = useState([]);
  const callisref = useRef(false);
  const [fileName, setFileName] = useState("");
  // const [conected, setconected] = useState(false);
  const [display, setdisplay] = useState(false);
  const location = useLocation();
  const [callDuration, setCallDuration] = useState("00:00");
  const [notificationCount, setNotificationCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
  const filteredConversations = UsersConv.filter((user) =>
    `${user.first_name} ${user.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  const updateCallDuration = (startTime) => {
    const diff = new Date() - startTime;
    const minutes = Math.floor(diff / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    setCallDuration(
      `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    );
  };
  useEffect(() => {
    let interval;
    if (callStatus === "active") {
      const startTime = new Date();
      interval = setInterval(() => updateCallDuration(startTime), 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [callStatus]);
  const {
    notificationCounts,
    updateNotificationCount,
    resetNotificationCount,
    getTotalNotifications,
    // messagenotification,
    addMessageNotification,
    // clearMessageNotifications,
    // removeMessageNotification,
    // setInitialNotificationCounts,
  } = useSignalingStore();
  const totalNotifications = getTotalNotifications();

  const iceServers = [
    { urls: "stun:stun.relay.metered.ca:80" },
    {
      urls: "turn:global.relay.metered.ca:80",
      username: "b7ccc598c5ad0d92a7dbc2c2",
      credential: "ujv2zwyoJ0QyKATN",
    },
    {
      urls: "turn:global.relay.metered.ca:80?transport=tcp",
      username: "b7ccc598c5ad0d92a7dbc2c2",
      credential: "ujv2zwyoJ0QyKATN",
    },
    {
      urls: "turn:global.relay.metered.ca:443",
      username: "b7ccc598c5ad0d92a7dbc2c2",
      credential: "ujv2zwyoJ0QyKATN",
    },
    {
      urls: "turns:global.relay.metered.ca:443?transport=tcp",
      username: "b7ccc598c5ad0d92a7dbc2c2",
      credential: "ujv2zwyoJ0QyKATN",
    },
  ];
  const senderId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const senderId = decodedToken.user_id;
      return senderId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    console.log("location ---------", location);
    if (location.state != null && location.state.candidate != null) {
      console.log("location2 ---------", location);
      console.log(
        "location.state.candidate.candidate_id ---------",
        location.state.candidate.candidate_id
      );
      setReceiverId(location.state.candidate.candidate_id);
    }

    if (senderId) {
      requestConversationUsersHistory();
    }
  }, [location, senderId]);

  const socketRef = useRef(null);

  useEffect(() => {
    console.log("the url is  ", apiUrlsoket);
    if (senderId) {
      if (socketRef.current) {
        socketRef.current.close();
      }
      const socket = new WebSocket(`${apiUrlsoket}ws/chat/${senderId}/0/`);

      socket.onopen = () => {
        console.log("WebSocket connected");
        socket.send(
          JSON.stringify({ type: "request_conversation_users_history" })
        );
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "conversation_users_history") {
          setUsersConv(data.users);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected");
      };

      socketRef.current = socket;

      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [senderId]);
  const requestConversationUsersHistory = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({ type: "request_conversation_users_history" })
      );
    }
  };

  useEffect(() => {
    requestConversationUsersHistory();
  }, [totalNotifications]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, Messageshystory]);
  useEffect(() => {
    if (senderId && receiverId) {
      console.log("test");
      getHistory();
      connectToSocket(senderId, receiverId);
    }

    return () => {
      setMessages([]);
      setReceivedMessages([]);
    };
  }, [senderId, receiverId]);

  const scrollToBottom = () => {
    if (historyRef.current) {
      console.log("is executeeeeeeeeeeeeeeed");
      setTimeout(() => {
        historyRef.current.scrollTop = historyRef.current.scrollHeight;
      }, 100); // 100ms delay
      console.log("its endeeeed");
    }
  };
  const signalingServer2 = useSignalingStore.getState().signalingServer;
  const sendNotification = (message, sid, rid) => {
    if (signalingServer2 && signalingServer2.readyState === WebSocket.OPEN) {
      const notificationData = {
        type: "notif_messages",
        notif: `New message from ${sid}: ${message.substring(0, 20)}...`,
        receiver_id: rid,
      };

      signalingServer2.send(JSON.stringify(notificationData));
      console.log("Notification sent successfully");
    } else {
      console.log("WebSocket is not connected. Cannot send notification.");
    }
  };
  const handleConversationClick = async (userId) => {
    setdisplay(true);
    setReceiverId(userId);
    resetNotificationCount(userId);
    console.log("receiverId", userId);

    try {
      const response = await fetch(
        `${apiUrl}messaging/api/mark-notifications-as-read/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            sender_id: userId,
            recipient_id: senderId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to mark notifications as read");
      }

      useSignalingStore.getState().resetNotificationCount(userId);
      useSignalingStore.getState().clearMessageNotifications();
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const updateCallStatus = async () => {
      try {
        await fetch(`${apiUrl}messaging/api/end-call/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ sender_id: senderId }),
          signal: abortController.signal,
        });
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error updating call status:", error);
        }
      }
    };

    updateCallStatus();

    return () => {
      abortController.abort();
    };
  }, [apiUrl, senderId]);

  useEffect(() => {
    const fetchUnreadMessageCounts = async () => {
      try {
        const response = await fetch(
          `${apiUrl}messaging/api/unread-message-counts/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch unread message counts");
        }
        const data = await response.json();
        console.log("you unreaded messages ", data);
        useSignalingStore.getState().setInitialNotificationCounts(data);
      } catch (error) {
        console.error("Error fetching unread message counts:", error);
      }
    };
    fetchUnreadMessageCounts();
  }, []);
  const handleMessageSend = () => {
    if (
      signalingServer.current &&
      signalingServer.current.readyState === WebSocket.OPEN &&
      message.trim() !== ""
    ) {
      const timestamp = new Date().toISOString();
      const messageData = {
        type: "chat_message",
        message,
        timestamp,
        sender_id: senderId,
        receiver_id: receiverId,
      };
      signalingServer.current.send(JSON.stringify(messageData));

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: message,
          timestamp,
          sender: parseInt(senderId),
          recipient: parseInt(receiverId),
        },
      ]);
      sendNotification(message, senderId, receiverId);
      resetNotificationCount(receiverId);

      setMessage("");
      scrollToBottom();
    }
  };
  useEffect(() => {
    console.log("userId is : ", senderId);
  }, [senderId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, Messageshystory]);
  useEffect(() => {
    console.log("................................incall is ", incall);
  }, [incall]);

  useEffect(() => {
    console.log("senderId", senderId);
    console.log("reciever id", receiverId);
  }, [senderId, receiverId]);

  const toggleScreenShare = async () => {
    if (!isScreenSharing) {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        const screenTrack = screenStream.getTracks()[0];
        screenTrackRef.current = screenTrack;

        const sender = peerConnection.current
          .getSenders()
          .find((s) => s.track.kind === "video");
        sender.replaceTrack(screenTrack);

        screenTrack.onended = () => {
          stopScreenShare();
        };

        setIsScreenSharing(true);
      } catch (error) {
        console.error("Error sharing screen:", error);
      }
    } else {
      stopScreenShare();
    }
  };

  const stopScreenShare = () => {
    if (screenTrackRef.current) {
      screenTrackRef.current.stop();
      const localStream = localVideoRef.current.srcObject;
      const localVideoTrack = localStream
        .getTracks()
        .find((track) => track.kind === "video");

      const sender = peerConnection.current
        .getSenders()
        .find((s) => s.track.kind === "video");
      sender.replaceTrack(localVideoTrack);

      screenTrackRef.current = null;
      setIsScreenSharing(false);
    }
  };

  const getHistory = async () => {
    try {
      const response = await fetch(
        `${apiUrl}messaging/api/chat/${receiverId}/${senderId}/history/`
      );
      if (!response.ok) throw new Error("Failed to fetch history");
      const result = await response.json();
      setMessageshystory(result);
      console.log("Messageshystory", Messageshystory);
      scrollToBottom();
    } catch (error) {
      console.error("Error fetching history:", error.message);
    }
  };
  useEffect(() => {
    const senderId = getUserIdFromAccessToken();
    if (senderId) {
      connectToSocket(senderId, "0");
    }
    return () => {
      if (signalingServer.current) {
        signalingServer.current.close();
      }
    };
  }, []);
  useEffect(() => {
    console.log("Updated notification count:", notificationCount);
  }, [notificationCount]);

  const connectToSocket = (senderId, receiverId) => {
    const signalingServerUrl = `${apiUrlsoket}ws/chat/${senderId}/${receiverId}/`;
    signalingServer.current = new WebSocket(signalingServerUrl);
    if (receiverId === 0) {
      setdisplay(false);
    } else if (receiverId != 0) {
      setdisplay(true);
    }

    signalingServer.current.onmessage = async (message) => {
      const data = JSON.parse(message.data);
      console.log("Message from server:", data);

      if (data.type === "offer") {
        if (peerConnection.current.signalingState !== "stable") {
          console.error(
            "PeerConnection is not in a stable state:",
            peerConnection.current.signalingState
          );
          return;
        }
        callTypeRef.current = data.callType;
        await peerConnection.current.setRemoteDescription(
          new RTCSessionDescription(data.offer)
        );
        console.log("test");
        setCallStatus("ringing");
        ringAudioRef.current.play();
        console.log("----------in answer ", data.sender_id);
        setupdateconv(data.sender_id);
      } else if (data.type === "answer") {
        if (peerConnection.current.signalingState !== "have-local-offer") {
          console.error(
            "PeerConnection is not in a state to accept an answer:",
            peerConnection.current.signalingState
          );
          return;
        }
        await peerConnection.current.setRemoteDescription(
          new RTCSessionDescription(data.answer)
        );
        console.log("test2", data);
        setCallStatus("active");
      } else if (data.type === "ice_candidate") {
        try {
          await peerConnection.current.addIceCandidate(
            new RTCIceCandidate(data.candidate)
          );
        } catch (error) {
          console.error("Failed to add ICE candidate:", error);
        }
      } else if (data.type === "conversation_users_history") {
        setUsersConv(data.users);
        setLoading(false);
      } else if (data.type === "call_ended") {
        callisref.current = false;
        endCall();
      } else if (data.type === "chat_message") {
        console.log("the resiiiiiiiiiiiiiive", data.message);

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            content: data.message,
            timestamp: data.timestamp,
            sender: parseInt(data.sender_id),
            recipient: parseInt(data.receiver_id),
          },
        ]);
        updateNotificationCount(
          data.sender_id,
          (notificationCounts[data.sender_id] || 0) + 1
        );
        addMessageNotification({
          id: Date.now(),
          message: data.message,
        });
      }

      if (data.type === "offer") {
      } else if (data.type === "busy") {
        callisref.current = data.call;
      } else if (data.type === "file_message") {
        console.log("Received file message:", data);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "file_message",
            fileName: data.fileName,
            fileType: data.fileType,
            fileData: data.fileData,
            sender: parseInt(data.sender_id),
            recipient: parseInt(data.receiver_id),
            timestamp: data.timestamp,
          },
        ]);
        updateNotificationCount(
          data.sender_id,
          (notificationCounts[data.sender_id] || 0) + 1
        );
        addMessageNotification({
          id: Date.now(),
          message: data.message,
        });
      } else if (data.type === "user_state_change") {
        // setconected(true);
      }
    };
    signalingServer.current.onclose = () => {
      console.log("Signaling server disconnected");
    };
    signalingServer.current.onopen = () => {
      console.log(
        "Signaling server connected",
        "senderId",
        senderId,
        " with conversation receiverId",
        receiverId
      );
    };

    peerConnection.current = new RTCPeerConnection({ iceServers });

    peerConnection.current.onicecandidate = (event) => {
      if (
        event.candidate &&
        signalingServer.current.readyState === WebSocket.OPEN
      ) {
        signalingServer.current.send(
          JSON.stringify({
            type: "ice_candidate",
            candidate: event.candidate,
            sender_id: senderId,
            receiver_id: receiverId,
          })
        );
      }
    };

    peerConnection.current.ontrack = (event) => {
      remoteVideoRef.current.srcObject = event.streams[0];
    };
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const startLocalStream = async () => {
    console.log("att the start of startlocal stream");
    signalingServer.current.send(
      JSON.stringify({
        type: "handele_check_if_busy",
        sender_id: senderId,
        receiver_id: receiverId,
      })
    );
    console.log("after the send handele_check_if_busy");
    await delay(3000);
    if (callisref.current) {
      console.log("busy");
      toast.info("The user you're trying to call is currently busy.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!peerConnection.current) {
      peerConnection.current = new RTCPeerConnection({ iceServers });
    }

    if (!signalingServer.current) {
      signalingServer.current = new WebSocket(
        `${apiUrlsoket}ws/chat/${senderId}/${receiverId}/`
      );
    }

    const localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    localVideoRef.current.srcObject = localStream;
    localStream
      .getTracks()
      .forEach((track) => peerConnection.current.addTrack(track, localStream));

    const offer = await peerConnection.current.createOffer();
    await peerConnection.current.setLocalDescription(offer);
    signalingServer.current.send(
      JSON.stringify({
        type: "offer",
        offer: offer,
        sender_id: senderId,
        receiver_id: receiverId,
        callType: "video",
      })
    );
    setCallStatus("active");
  };

  const startLocalAudioStream = async () => {
    callTypeRef.current = "audio";

    console.log("att the start of startlocal stream");

    signalingServer.current.send(
      JSON.stringify({
        type: "handele_check_if_busy",

        sender_id: senderId,

        receiver_id: receiverId,
      })
    );

    console.log("after the send handele_check_if_busy");

    await delay(3000);

    if (callisref.current) {
      console.log("busy");

      return;
    }

    if (!peerConnection.current) {
      peerConnection.current = new RTCPeerConnection({ iceServers });
    }

    if (!signalingServer.current) {
      signalingServer.current = new WebSocket(
        `${apiUrlsoket}ws/chat/${senderId}/${receiverId}/`
      );
    }

    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    localVideoRef.current.srcObject = localStream;
    localStream
      .getTracks()
      .forEach((track) => peerConnection.current.addTrack(track, localStream));

    const offer = await peerConnection.current.createOffer();
    await peerConnection.current.setLocalDescription(offer);
    signalingServer.current.send(
      JSON.stringify({
        type: "offer",
        offer: offer,
        sender_id: senderId,
        callType: "audio",
        receiver_id: receiverId,
      })
    );
    setCallStatus("active");
  };

  const stopScreenSharing = () => {
    screenStreamRef.current.getTracks().forEach((track) => track.stop());
    screenStreamRef.current = null;
    setScreenSharing(false);
  };

  const acceptCall = async () => {
    console.log(
      "Accepting call with senderId:",
      senderId,
      "receiverId:",
      receiverId,
      "new is ",
      updateconv
    );
    {
      console.log("Rendering with Messageshystory:", Messageshystory);
    }
    getHistory();
    ringAudioRef.current.pause();
    ringAudioRef.current.currentTime = 0;

    const localStream = await navigator.mediaDevices.getUserMedia({
      video: callTypeRef.current === "video",
      audio: true,
    });
    localVideoRef.current.srcObject = localStream;
    localStream
      .getTracks()
      .forEach((track) => peerConnection.current.addTrack(track, localStream));

    const answer = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(answer);
    if (updateconv) {
      setReceiverId(updateconv);
      signalingServer.current.send(
        JSON.stringify({
          type: "answer",
          answer: answer,
          sender_id: senderId,
          receiver_id: updateconv,
          incall: [senderId, updateconv],
        })
      );
    } else {
      signalingServer.current.send(
        JSON.stringify({
          type: "answer",
          answer: answer,
          sender_id: senderId,
          receiver_id: receiverId,
          incall: [senderId, receiverId],
        })
      );
    }

    setCallStatus("active");
    console.log("---senderId ", typeof senderId);
    setIncall((prevIncall) => [...prevIncall, parseInt(senderId)]);

    console.log("---receiverId ", typeof receiverId);
    setIncall((prevIncall) => [...prevIncall, receiverId]);

    console.log("incall is ", incall);
  };

  const endCall = () => {
    if (localVideoRef.current && localVideoRef.current.srcObject) {
      localVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
      localVideoRef.current.srcObject = null;
    }

    if (peerConnection.current) {
      peerConnection.current.close();
      peerConnection.current = null;
    }

    if (signalingServer.current) {
      const targetId = updateconv || receiverId;
      if (signalingServer.current.readyState === WebSocket.OPEN) {
        signalingServer.current.send(
          JSON.stringify({
            type: "call_ended",
            sender_id: senderId,
            receiver_id: targetId,
          })
        );
      }
      setCallDuration("00:00");

      signalingServer.current.close();
      signalingServer.current = null;
    }

    if (ringAudioRef.current) {
      ringAudioRef.current.pause();
      ringAudioRef.current.currentTime = 0;
    }

    if (screenSharing) {
      stopScreenSharing();
    }

    callisref.current = false;
    setReceiverId(updateconv || receiverId);
    setTimeout(() => {
      connectToSocket(senderId, receiverId);
    }, 1000);
    setCallStatus("idle");
  };

  const MAX_FILE_SIZE = 500 * 1024 * 1024;

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert(
          `File is too large. Maximum size is ${MAX_FILE_SIZE / 1024 / 1024}MB.`
        );
        event.target.value = null;
        setSelectedFile(null);
        setFileName(null);
      } else {
        setSelectedFile(file);
        setFileName(file.name);
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
      handleFileUpload();
    }
  };

  const fileempty = () => {
    setSelectedFile(null);
    setFileName(null);
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const fileMessage = {
          type: "file_message",
          fileName: selectedFile.name,
          fileType: selectedFile.type,
          fileData: fileData,
          sender_id: senderId,
          receiver_id: receiverId,
          timestamp: new Date().toISOString(),
        };
        signalingServer.current.send(JSON.stringify(fileMessage));

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "file_message",
            fileName: selectedFile.name,
            fileType: selectedFile.type,
            fileData: fileData,
            sender: parseInt(senderId),
            recipient: parseInt(receiverId),
            timestamp: new Date().toISOString(),
          },
        ]);

        setSelectedFile(null);
        setFileName(null);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const renderMessage = (msg) => {
    const isFileMessage =
      msg.file_name || msg.fileName || msg.type === "file_message";
    const fileName = msg.file_name || msg.fileName;
    const fileType = msg.file_type || msg.fileType;
    const fileData = msg.file_data || msg.fileData;

    if (isFileMessage) {
      if (fileType && fileType.startsWith("image/")) {
        return (
          <img
            src={fileData}
            alt={fileName}
            style={{ maxWidth: "200px", maxHeight: "200px", cursor: "pointer" }}
            onClick={() => window.open(fileData, "_blank")}
          />
        );
      } else {
        return (
          <a href={fileData} download={fileName}>
            Download {fileName}
          </a>
        );
      }
    } else {
      return <div className="message-text">{msg.content}</div>;
    }
  };

  return (
    <div
      className={`content App ${callStatus === "active" ? "call-active" : ""}`}
    >
      <ToastContainer />

      <div
        className={`videocalldisplay ${
          callTypeRef.current === "audio" ? "audio-call" : ""
        }`}
        style={{ position: "relative" }}
      >
        <div
          className={`localvideo ${
            callTypeRef.current === "audio" ? "audio-only" : ""
          }`}
        >
          <video ref={localVideoRef} autoPlay muted className="video"></video>
        </div>
        <div className="call-duration">{callDuration}</div>
        <div
          className={`remotvideo ${
            callTypeRef.current === "audio" ? "audio-only" : ""
          }`}
        >
          <video ref={remoteVideoRef} autoPlay className="video"></video>
        </div>
      </div>

      {callStatus !== "active" && (
        <div className="conversation-container">
          {loading && <p>Loading...</p>}
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <ul className="message-list">
            {filteredConversations.map((user, index) => (
              <li
                key={index}
                className={
                  user.id == senderId ? "received-message" : "sent-message"
                }
              >
                <button
                  className="conversation-button"
                  onClick={() => {
                    handleConversationClick(user.id);
                    console.log("receiverId", user.id);
                  }}
                >
                  {user.first_name} {user.last_name}{" "}
                  {notificationCounts[user.id] > 0 && (
                    <span className="notification-badge">
                      {notificationCounts[user.id]}
                    </span>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="chat-history1">
        {display && (
          <div className="video-call-container">
            {(callStatus === "idle" || callStatus === "ended") && (
              <>
                <img
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => {
                    callTypeRef.current = "video";
                    startLocalStream();
                  }}
                  // src={require("assets/img/video-camera.png")}
                  src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679465/jzp3peniwdilyxv58t0z.png'

                  alt="Video Call"
                />
                <img
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => {
                    callTypeRef.current = "audio";
                    startLocalAudioStream();
                  }}
                  src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679654/uiaujmqhpr9t3ur7y4nq.png'
                  alt="Voice Call"
                />
              </>
            )}
            {callStatus === "ringing" && (
              <>
                <img
                  style={{ width: "30px", height: "30px" }}
                  src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679798/grp6xmgiq8kyjfzdkfmd.png'
                  onClick={acceptCall}
                />

                <img
                  style={{ width: "30px", height: "30px" }}
                  src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679832/kk4vhf2u9axne003xixs.png'
                  onClick={endCall}
                />
              </>
            )}
            {callStatus === "active" && (
              <div className="call-controls">
                <img
                  style={{ width: "30px", height: "30px" }}
                  src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679832/kk4vhf2u9axne003xixs.png'
                  onClick={endCall}
                />
                {callTypeRef.current === "video" ? (
                  <button className="screen-share" onClick={toggleScreenShare}>
                    {isScreenSharing ? (
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679906/nl2bfcmhue28znzrtlta.png'
                      />
                    ) : (
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src='https://res.cloudinary.com/dwckvxlca/image/upload/v1726679942/zvxhmbpojgqrpwb8w8xq.png'
                      />
                    )}
                  </button>
                ) : (
                  ""
                )}
              </div>
            )}

            <audio
              ref={ringAudioRef}
              src={require("assets/img/messenger_video_call.mp3")}
              loop
            ></audio>
          </div>
        )}

        <ul className="chat-history2" ref={historyRef}>
          {Messageshystory.map((msg, index) => (
            <li
              key={index}
              className={`message-item ${
                msg.sender == senderId ? "receiver" : "sender"
              }`}
            >
              <div className="message-content">
                {renderMessage(msg)}
                <div className="message-timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </div>
              </div>
            </li>
          ))}
          <li className="new-message-divider">new messages</li>
          <ul className="message-list">
            {messages
              .filter(
                (msg) =>
                  (msg.recipient == parseInt(receiverId) &&
                    msg.sender == parseInt(senderId)) ||
                  (msg.recipient == parseInt(senderId) &&
                    msg.sender == parseInt(receiverId))
              )
              .map((msg, index) => (
                <li
                  key={index}
                  className={`message-item ${
                    msg.sender == senderId ? "receiver" : "sender"
                  }`}
                >
                  <div className="message-content">
                    {renderMessage(msg)}
                    <div className="message-timestamp">
                      {new Date(msg.timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </ul>

        {display && (
          <>
            <div className="input-container">
              <input
                className="input-messages"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type your message..."
              />
              <IconButton
                variant="contained"
                onClick={() => {
                  handleMessageSend();
                  handleFileUpload();
                }}
                sx={{ width: "10%", color: "#000", marginLeft: "10px" }}
              >
                <SendIcon fontSize="large" />
              </IconButton>

              {!fileName && (
                <IconButton
                  component="label"
                  color="primary"
                  aria-label="upload file"
                  size="large"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 40,
                    },
                  }}
                >
                  <CloudUploadIcon />
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
                  />
                </IconButton>
              )}
              {fileName && (
                <IconButton aria-label="delete" onClick={fileempty}>
                  {fileName}
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;